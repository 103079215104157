$(document).ready(function () {
  initBooking();
  initTabLinks();
  initCustomSelect();
  initUpdatedBookingBar();
});

function initBooking() {
  $(".booking-bar__form, .booking-menu").each(function () {
    initDateSelection(this);
  });

  $(
    ".booking-bar__why-book button, .booking-bar-popup__inner .close-button, .booking-menu__why-book button"
  ).on("click", function () {
    $(".booking-menu__close").trigger("click");
    $(".booking-bar-popup").slideToggle();
  });

  $(".booking-bar__why-book button").on("click", function (e) {
    e.preventDefault(); // Stop this button from also submitting the form
  });

  if ($(".booking-bar__logo--right img").length > 0) {
    $(".booking-bar__wrapper").addClass("has-logo"); // Adds white border between booking bar and the logo, but only when the img element is there
  }
}

function formatDateForDateBlocks(date) {
  // Extracting the day (23)
  var CheckInDateNumber = date.getDate();

  // Optionally, get the full month name and year (August 2024)
  var fullMonthOptions = { month: "long", year: "numeric" };
  var CheckInDateFullMonthAndYear = date.toLocaleDateString(
    "en-US",
    fullMonthOptions
  );

  // Return an object containing both values
  return {
    CheckInDateNumber: CheckInDateNumber,
    CheckInDateFullMonthAndYear: CheckInDateFullMonthAndYear,
  };
}

function setBlockDates(checkInDate, checkOutDate) {
  var checkInNumber = checkInDate.CheckInDateNumber;
  var checkOutNumber = checkOutDate.CheckInDateNumber;

  // Add leading zero if the date number is a single digit
  if (checkInNumber < 10) {
    checkInNumber = "0" + checkInNumber;
  }

  if (checkOutNumber < 10) {
    checkOutNumber = "0" + checkOutNumber;
  }

  // Set the HTML content with the modified date numbers
  $("#check-in-block .number").html(checkInNumber);
  $("#check-in-block .text").html(checkInDate.CheckInDateFullMonthAndYear);

  $("#check-out-block .number").html(checkOutNumber);
  $("#check-out-block .text").html(checkOutDate.CheckInDateFullMonthAndYear);
}

// Set default dates
$(document).ready(function () {
  // Get today's date
  var today = new Date();

  // Get tomorrow's date
  var tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  // Format today's date for the check-in block
  var checkInDate = {
    CheckInDateNumber: today.getDate(), // Day number
    CheckInDateFullMonthAndYear: today.toLocaleString("default", {
      month: "long",
      year: "numeric",
    }), // Full month and year
  };

  // Format tomorrow's date for the check-out block
  var checkOutDate = {
    CheckInDateNumber: tomorrow.getDate(), // Day number
    CheckInDateFullMonthAndYear: tomorrow.toLocaleString("default", {
      month: "long",
      year: "numeric",
    }), // Full month and year
  };

  // Populate the blocks using the setBlockDates function
  setBlockDates(checkInDate, checkOutDate);
});

function initDateSelection(bookingContainer) {
  var nowTemp = new Date();

  // Don't allow bookings before the opening date
  var availability = $(bookingContainer)
    .find('button[type="submit"]')
    .first()
    .data("availability");

  var openingDate;
  if (typeof availability === "undefined") {
    openingDate = new Date(); // Use current date if no availability is set
  } else {
    var parts = availability.split("-");
    openingDate = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2])); // Year, Month (0-based), Day
  }

  var tomorrow = new Date(openingDate);
  tomorrow.setUTCDate(tomorrow.getUTCDate() + 1); // Add 1 day in UTC

  if (nowTemp < openingDate) {
    nowTemp = openingDate;
  }

  tomorrow.setUTCDate(nowTemp.getUTCDate() + 1);

  var $checkInField = $(bookingContainer).find(".check-in");
  var $checkOutField = $(bookingContainer).find(".check-out");

  $checkInField.on("hide.datepicker", function () {
    var checkInDate = $checkInField.datepicker("getDate");
    checkInDate.setDate(checkInDate.getUTCDate());
    var checkOutDate = $checkOutField.datepicker("getDate");
    var dayAfterCheckIn = new Date(checkInDate);
    dayAfterCheckIn.setUTCDate(checkInDate.getUTCDate() + 1);

    // Ensure checkout comes after checkin.
    // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
    if (checkInDate >= checkOutDate) {
      $checkOutField.datepicker("setDate", dayAfterCheckIn);
    }

    $checkOutField.datepicker("setStartDate", dayAfterCheckIn);

    $checkOutField[0].focus();
  });

  var dateFormat =
    $("body.theme-palm-house").length > 0 ? "mm/dd/yy" : "dd/mm/yy";

  var checkin = $checkInField.datepicker({
    weekStart: 1,
    format: dateFormat,
    autoHide: true,
    date: null,
    startDate: nowTemp,
  });

  var checkout = $checkOutField.datepicker({
    weekStart: 1,
    format: dateFormat,
    autoHide: true,
    date: null,
    startDate: tomorrow,
  });

  // Swap the date format into year-month-day for Synxis
  $(bookingContainer).on("submit", function () {
    var checkInDate = $checkInField.datepicker("getDate");
    var checkOutDate = $checkOutField.datepicker("getDate");

    $(bookingContainer)
      .find('[name="arrive"]')
      .val(
        checkInDate.getFullYear() +
          "-" +
          pad(checkInDate.getMonth() + 1, 2) +
          "-" +
          pad(checkInDate.getDate(), 2)
      );
    $(bookingContainer)
      .find('[name="depart"]')
      .val(
        checkOutDate.getFullYear() +
          "-" +
          pad(checkOutDate.getMonth() + 1, 2) +
          "-" +
          pad(checkOutDate.getDate(), 2)
      );
  });

  var checkIn = $(".booking-menu__wrapper .check-in");
  var checkOut = $(".booking-menu__wrapper .check-out");

  var formatPlaceholder = function (date, format) {
    if (format === "mm/dd/yy") {
      return (
        pad(date.getUTCMonth() + 1, 2) +
        "/" +
        pad(date.getUTCDate(), 2) +
        "/" +
        date.getUTCFullYear()
      );
    } else {
      return (
        pad(date.getUTCDate(), 2) +
        "/" +
        pad(date.getUTCMonth() + 1, 2) +
        "/" +
        date.getUTCFullYear()
      );
    }
  };

  checkIn.attr(
    "placeholder",
    checkIn
      .data("ilhTemplate")
      .replace("#", formatPlaceholder(openingDate, dateFormat))
  );
  checkOut.attr(
    "placeholder",
    checkOut
      .data("ilhTemplate")
      .replace("#", formatPlaceholder(tomorrow, dateFormat))
  );
}

function initTabLinks() {
  $(".tabs-title a").on("click", function () {
    if ($(this).hasClass("link-not-tab")) {
      console.log($("#" + $(this).attr("href").replace("#", "")).find("a"));
      $("#" + $(this).attr("href").replace("#", ""))
        .find("a")[0]
        .click(); // click the link inside the tab when the tab title is clicked
      return false;
    }
  });

  $('select[name="booking-select"]').on("change", function () {
    if ($(this).val() === "gift") {
      $('.booking-menu__tabs a[href="#gift"]')[0].click();
    } else if ($(this).val() === "class") {
      $('.booking-menu__tabs a[href="#class"]')[0].click();
    } else if ($(this).val().indexOf("table") > -1) {
      $('#table a[data-link="' + $(this).val() + '"]')[0].click();
    } else if ($(this).val().indexOf("spa") > -1) {
      $(".book-now").trigger("click");
      $("#spa-label").trigger("click");
    }
  });
}

function initCustomSelect() {
  // Enumerate the table booking options
  var count = 0;
  $(".booking-bar__selector option").each(function () {
    if ($(this).attr("value") == "table-booking") {
      $(this).attr("value", $(this).attr("value") + "-" + count);
      count++;
    }
  });

  count = 0;
  $(".booking-menu__links a").each(function () {
    if ($(this).attr("data-link") == "table-booking") {
      // console.log(this);
      $(this).attr("data-link", $(this).attr("data-link") + "-" + count);
      count++;
    }
  });

  $(".custom-select select").selectric();
}

function initUpdatedBookingBar() {
  var roomAccordion = $(".accordion.rooms");
  var guestAccordion = $(".accordion.guests");
  var roomSelectorBtn = $(".booking-rooms-summary");
  var guestSelectorBtn = $(".booking-bar-summary");

  var state = {
    isRoomActive: false,
    isGuestsActive: false,
  };

  var values = {
    rooms: 0,
    adults: 2,
    children: 0,
    maxRoom: 5,
    maxAdults: 8,
    maxChildren: 8,
  };

  var inputs = {
    rooms: $(".booking-bar-rooms"),
    adults: $(".booking-bar-adults"),
    children: $(".booking-bar-children"),
  };

  var labels = {
    rooms: $(".rooms-value"),
    adults: $(".adults-value"),
    children: $(".children-value"),
  };

  function setDefaultValue(number, input) {
    input.val(number);
  }

  function setAccordion(state, element) {
    if (state) {
      element.show();
    } else {
      element.hide();
    }
  }

  function handleAccordionChange(state, element1, element2) {
    if (state) {
      element1.show();
      element2.hide();
    } else {
      element1.hide();
    }
  }

  function handleClickOutSideOfTarget(e, accordion, selector, state) {
    if (
      !accordion.is(e.target) &&
      accordion.has(e.target).length === 0 &&
      !selector.is(e.target)
    ) {
      state = false;
      accordion.hide();
    }

    return;
  }

  function toggleGuestsAccordion(e) {
    state.isGuestsActive = !state.isGuestsActive;
    state.isRoomActive = false;
    handleAccordionChange(state.isGuestsActive, guestAccordion, roomAccordion);
    if (e.target.id === "booking-menu-summary") {
      $("#acc-bar-guests").css("opacity", 0);
    }
  }

  function toggleRoomAccordion(e) {
    state.isRoomActive = !state.isRoomActive;
    state.isGuestsActive = false;
    handleAccordionChange(state.isRoomActive, roomAccordion, guestAccordion);

    if (e.target.id === "booking-menu-rooms") {
      $("#acc-bar-rooms").css("opacity", 0);
    }
  }

  function appendNumOfGuests() {
    var template = guestSelectorBtn
      .filter("[data-ilh-template]")
      .data("ilhTemplate")
      .replace("#", values.adults + values.children);
    guestSelectorBtn.val(template);
  }

  function appendNumOfRooms() {
    var template = roomSelectorBtn
      .filter("[data-ilh-template]")
      .data("ilhTemplate")
      .replace("#", values.rooms);
    roomSelectorBtn.val(template);
  }

  function setData(value, targetInput, targetLabel) {
    targetInput.val(value);
    targetLabel.html(value);
  }

  function incrementRooms() {
    if (values.rooms < values.maxRoom) {
      values.rooms++;
    }
    setData(values.rooms, inputs.rooms, labels.rooms);
    appendNumOfRooms();
  }

  function decrementRooms() {
    if (values.rooms > 0) {
      values.rooms--;
    }
    setData(values.rooms, inputs.rooms, labels.rooms);
    appendNumOfRooms();
  }

  function incrementAdults() {
    if (values.adults < values.maxAdults) {
      values.adults++;
    }
    setData(values.adults, inputs.adults, labels.adults);
    appendNumOfGuests();
  }

  function decrementAdults() {
    if (values.adults > 0) {
      values.adults--;
    }
    setData(values.adults, inputs.adults, labels.adults);
    appendNumOfGuests();
  }

  function incrementChildren() {
    if (values.children < values.maxChildren) {
      values.children++;
    }
    setData(values.children, inputs.children, labels.children);
    appendNumOfGuests();
  }

  function decrementChildren() {
    if (values.children > 0) {
      values.children--;
    }
    setData(values.children, inputs.children, labels.children);
    appendNumOfGuests();
  }

  setDefaultValue(2, inputs.adults);
  setAccordion(state.isRoomActive, roomAccordion);
  setAccordion(state.isGuestsActive, guestAccordion);

  guestSelectorBtn.on("click", function (e) {
    toggleGuestsAccordion(e);
    appendNumOfGuests();
  });
  roomSelectorBtn.on("click", function (e) {
    toggleRoomAccordion(e);
  });
  $(".booking-bar").on("click", function (e) {
    handleClickOutSideOfTarget(
      e,
      roomAccordion,
      roomSelectorBtn,
      state.isRoomActive
    );
    handleClickOutSideOfTarget(
      e,
      guestAccordion,
      guestSelectorBtn,
      state.isGuestsActive
    );
  });
  $(".booking-menu").on("click", function (e) {
    handleClickOutSideOfTarget(
      e,
      roomAccordion,
      roomSelectorBtn,
      state.isRoomActive
    );
    handleClickOutSideOfTarget(
      e,
      guestAccordion,
      guestSelectorBtn,
      state.isGuestsActive
    );
  });
  $(".increment-rooms").on("click", incrementRooms);
  $(".decrement-rooms").on("click", decrementRooms);
  $(".increment-adults").on("click", incrementAdults);
  $(".decrement-adults").on("click", decrementAdults);
  $(".increment-children").on("click", incrementChildren);
  $(".decrement-children").on("click", decrementChildren);

  $(".booking-menu__close").on("click", function () {
    $("#acc-bar-rooms").css("opacity", 1);
    $("#acc-bar-guests").css("opacity", 1);
  });
}
